/* MINI_PAGE */

.mini-page-card {
  /* border: 1px solid #3dcdff; */
  border-radius: 13px;
  padding: 5% 8%;
  background: linear-gradient(
    122.79deg,
    rgba(255, 255, 255, 0.1) 5.39%,
    rgba(255, 255, 255, 0.03) 63.48%
  );
}
.mini-page {
  margin: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mini-page-small-card {
  background: linear-gradient(
    122.3deg,
    rgba(255, 255, 255, 0.1) 5.05%,
    rgba(255, 255, 255, 0.03) 62.97%
  );
  border: 1px solid #3dcdff;
  max-width: 900px;
  margin: auto;
  border-radius: 16px;
}
.mini-page h2 {
  font-size: var(--font-3md);
  color: var(--white);
  font-weight: 600;
}
.mini-page p {
  font-size: var(--font-md);
  color: var(--white);
  font-weight: 400;
}
.mini-page-select {
  border: 1px solid #3dcdff;
  border-radius: 16px;
  font-size: var(--font-xs);
  font-weight: 700;
  opacity: 0.8;
  color: var(--white);
  padding: 18px 22px;
  background-color: transparent;
  outline: none;
  appearance: none;
  background-image: url("../../assets/img/png/select-img.png");
  background-repeat: no-repeat;
  background-position-x: 93%;
  background-position-y: center;
}
.img-1 {
  max-width: 423px;
}
/* body {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto;
  align-content: center;
  justify-content: center;
}
input {
  width: 255px;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
}
#red {
  accent-color: red;
} */
@media (min-width: 991.98px) {
  .w-lg-50 {
    width: 50%;
  }
}

.gradient-border {
  --border-width: 3px;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: white;
  background: #222;
  border-radius: var(--border-width);
}
.gradient-border::after {
  position: absolute;
  content: "";
  top: calc(-1 * var(--border-width));
  left: calc(-1 * var(--border-width));
  z-index: -1;
  width: calc(100% + var(--border-width) * 2);
  height: calc(100% + var(--border-width) * 2);
  background: linear-gradient(93.77deg, #3dcdff 12.63%, #b119ff 57.88%),
    linear-gradient(0deg, #ffffff, #ffffff);
  background-size: 300% 300%;
  background-position: 0 50%;
  border-radius: calc(2 * var(--border-width));
  animation: moveGradient 4s alternate infinite;
}
@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}
#greadient_range {
  /* background: linear-gradient(93.77deg, #3dcdff 12.63%, #b119ff 57.88%), linear-gradient(0deg, #ffffff, #ffffff) !important; */
  accent-color: #b119ff;
  width: 100%;
  z-index: 5;
  position: relative;
}

.filter-invert-1 {
  filter: invert(1);
}

.partner-img-size {
  width: 100%;
}

@media (max-width: 576.98px) {
  .partner-img-size {
    width: 70%;
  }
}

/* ===============================================================FONT FAMILY ============================================================================= */
@font-face {
  src: url("../../assets/font-family/font/BlenderPro-Bold.ttf");
  font-family: blenderfont;
}

body {
  background-color: black !important;
  font-family: blenderfont !important;
}

/* ==============================================================ROOT VARIABLES========================================================================== */
:root {
  --font-4xxl: 200px;
  --font-3xxl: 127px;
  --font-2xxl: 102px;
  --font-xxl: 80px;
  --font-xl: 72px;
  --font-lg: 61px;
  --font-md: 30px;
  --font-2md: 32px;
  --font-3md: 40px;
  --font-4md: 50px;
  --font-lg2: 60px;
  --font-sm: 28px;
  --font-2sm: 25px;
  --font-xs: 22px;
  --font-2xs: 20px;
  --font-2xs: 16px;
  --font-3xs: 16px;
  /* ===========================================================COLORS ====================================================================================== */
  --white: #ffffff;
  --grey: #e5e5e5;
  --pink: #9216d0;
  --black: #000000;
  --sky-blue: #3dcdff;
}

/* ==============================================================MEDIA QUERIES ==========================================================================*/
@media (max-width: 1399.98px) {
  :root {
    --font-sm: 23px;
    --font-4xxl: 150px;
    --font-xxl: 55px;
    --font-xs: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1600.98px) {
  :root {
    --font-4xxl: 100px;
    --font-xxl: 60px;
    --font-3md: 35px;
    --font-md: 25px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  :root {
    --font-4xxl: 100px;
    --font-xxl: 30px;
    --font-3md: 30px;
    --font-md: 25px;
  }
}
@media (max-width: 991.98px) {
  :root {
    --font-4xxl: 100px;
    --font-3xxl: 122px;
    --font-2xxl: 95px;
    --font-xxl: 75px;
    --font-xl: 67px;
    --font-lg: 65px;
    --font-lg2: 50px;
    --font-md: 25px;
    --font-2md: 28px;
    --font-3md: 35px;
    --font-4md: 45px;
    --font-sm: 23px;
    --font-2sm: 21px;
    --font-xs: 22px;
    --font-2xs: 20px;
    --font-2xs: 19px;
    --font-3xs: 16px;
  }
}

@media (max-width: 767.98px) {
  :root {
    --font-4xxl: 95px;
    --font-3xxl: 119px;
    --font-2xxl: 90px;
    --font-xxl: 60px;
    --font-xl: 67px;
    --font-lg: 55px;
    --font-md: 21px;
    --font-2md: 25px;
    --font-lg2: 40px;
    --font-3md: 30px;
    --font-4md: 40px;
    --font-sm: 20px;
    --font-2sm: 18px;
    --font-xs: 15px;
    --font-2xs: 17px;
    --font-2xs: 19px;
    --font-3xs: 16px;
  }
}

@media (max-width: 575.98px) {
  :root {
    --font-4xxl: 50px;
    --font-3xxl: 55px;
    --font-2xxl: 45px;
    --font-xxl: 33px;
    --font-xl: 53px;
    --font-lg: 30px;
    --font-md: 20px;
    --font-2md: 21px;
    --font-3md: 25px;
    --font-4md: 30px;
    --font-sm: 16px;
    --font-2sm: 15px;
    --font-xs: 13px;
    --font-2xs: 14px;
    --font-2xs: 17px;
    --font-lg2: 30px;
    --font-3xs: 16px;
  }
}

/* ===============================================================COUSTM CSS =============================================================================*/
.header-text {
  font-size: var(--font-xs);
  font-weight: 700px;
  color: var(--white);
}
.text-greadient {
  background: linear-gradient(93.77deg, #3dcdff 12.63%, #b119ff 57.88%),
    linear-gradient(0deg, #ffffff, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.wallet-btn {
  color: var(--white);
  font-size: var(--font-sm);
}
.main-heading {
  font-size: var(--font-xxl);
  font-weight: 700;
}
.fs-xs {
  font-size: var(--font-xs);
}
.fs-2xs {
  font-size: var(--font-2xs);
}
.fs-sm {
  font-size: var(--font-sm);
}
.fs-md {
  font-size: var(--font-md);
}
.fs-3md {
  font-size: var(--font-3md);
}
.fs-4md {
  font-size: var(--font-4md);
}
.fs-3md {
  font-size: var(--font-3md);
}
.fs-2xxl {
  font-size: var(--font-2xxl);
}
.fs-lg2 {
  font-size: var(--font-lg2);
}
.para {
  font-size: var(--font-xs);
  font-weight: 700;
}
.paraSub {
  font-size: var(--font-xs);
  font-weight: 1000;
}
.container-xl {
  max-width: 1400px !important;
}

/* ===================================================================HEADER CSS ==========================================================================*/
.nav-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  max-width: 250px;
  width: 100%;
  /* margin: 0 20%; */
}
.logo-quest {
  height: 50px;
  width: 50px;
}
.nav-btn {
  background-image: url("../../assets/img/png/btn-bg.png");
  width: 250px;
  height: 65px;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: var(--font-sm);
  transition: all 0.3s ease-in-out;
}
.nav-btn:hover {
  transform: translateX(-10px);
}

.custom {
  padding: 0 17% 0 5% !important;
}

.navbar-wrapper {
  background: rgba(0, 0, 0, 0.65);
  box-shadow: inset 0px 4px 20px rgba(177, 25, 255, 0.8);
}

.navbar-wrapper .nav-list-items a {
  color: white;
  text-decoration: none;
  position: relative;
  font-size: var(--font-xs);
  font-weight: 700;
}

.navbar-wrapper .nav-list-items a::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 0;
  height: 3px;
  background-color: var(--sky-blue);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.navbar-wrapper .nav-list-items a:hover::after {
  width: 100%;
}

.overlay-active {
  z-index: 10;
  transition: 0.7s ease !important;
  opacity: 1 !important;
  pointer-events: all !important;
}

.pt-30 {
  padding-top: 30px;
}

.object-fit {
  object-fit: contain;
}
.object-fit-cover {
  object-fit: cover;
}
.mb-80 {
  margin-bottom: 80px;
}

.p-10 {
  padding: 10px;
}

.overlay-nav li {
  transform: translateY(10px);
  transition: all 0.4s ease-in-out;
  opacity: 0;
  list-style-type: none;
}

.overlay-active ul li:nth-child(1) {
  animation: animate_links 0.9s;
  animation-delay: 0.2s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(2) {
  animation: animate_links 0.9s;
  animation-delay: 0.4s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(3) {
  animation: animate_links 0.9s;
  animation-delay: 0.6s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(4) {
  animation: animate_links 0.9s;
  animation-delay: 0.8s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(5) {
  animation: animate_links 0.9s;
  animation-delay: 1s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(6) {
  animation: animate_links 0.9s;
  animation-delay: 1.2s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes animate_links {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(-20px);
    opacity: 1;
  }
}

.navbar-logo {
  height: 70px;
  width: 70px;
}

.h-screen {
  height: 100vh;
}

.fix-screen-height {
  height: 100vh;
  overflow: hidden;
}

/* ====================================================================HERO=============================================================================== */
.hero-img {
  background-image: url(../../assets/img/png/bg-img.png);
  min-height: calc(100vh - 100px);
  background-position: bottom;
  background-size: cover;
  width: 100%;
}

.hero-heading {
  font-size: var(--font-4xxl);
  color: var(--white);
  font-weight: 700;
  text-shadow: 5px 5px var(--pink);
}

.hero-text {
  color: var(--white);
  font-size: var(--font-md);
  font-weight: 700;
}

.btn-bg {
  background: url("../../assets/img/png/btn-bg.png");
  width: 231px;
  height: 59px;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}
.button_sm {
  background: url("../../assets/img/png/btn-bg.png");
  width: 160px;
  height: 46px;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}
.btn-bg:hover {
  transform: translateX(-10px);
}

.btn-bg-transparent {
  background: url("../../assets/img/png/btn-bg-transparent.png");
  width: 330px;
  height: 70px;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}
.btn-bg-transparent:hover {
  transform: translateX(-10px);
}

/*===================================================================== FOOTER CSS========================================================================= */
.footer-section {
  background-color: black;
}

.social-icons {
  transition: 0.5s;
}

.social-icons:hover {
  transform: translateY(-10px);
}

.footer-border {
  opacity: 0.1;
  border-bottom: 1px solid #ffffff;
}

.copyright-text {
  font-weight: 700;
  font-size: var(--font-3xs);
  color: var(--white);
  opacity: 0.7;
}

.footer-img {
  position: absolute;
  right: 0;
  bottom: 0;
}
/* ====================================================================Partners================================================================= */

.cards-section {
  background-color: var(--black);
}
.partner-blur-img {
  position: absolute;
  bottom: -393px;
  z-index: -3;
}
/* ==================================================================NFT SLIDER================================================================= */
.slider .slick-center img {
  transform: translateY(50px);
  z-index: 6;
}
.nft-land-slider .slick-active.slick-center img {
  box-shadow: 2px 2px 16px #3dcdff;
  border-radius: 6px;
}
.slick-dots li button:before {
  font-size: 10px !important;
  color: #b119ff !important;
}
.slider-blur-img {
  position: absolute;
  bottom: -130px;
  z-index: -4;
}
/* ===============================================================EARNING SECTION================================================================= */
.earning-heading {
  font-weight: 700;
  color: var(--white);
  font-size: var(--font-4md);
}
.earning-text {
  font-weight: 700;
  font-size: var(--font-2sm);
}
/* ===========================================================================FAQ SECTION========================================================== */
.accordion-button {
  color: var(--white);
  font-size: var(--font-md) !important;
  font-weight: 700;
}
.accordion-body {
  font-size: var(--font-2xs) !important;
  font-weight: 700;
}
.custom-border {
  border-bottom: 1px solid var(--pink);
}
.mw-740 {
  max-width: 740px;
}
.bg-faq-img {
  background: url("../../assets/img/png/hands.png");
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 64%;
  background-size: cover;
}
.purple-img {
  position: absolute;
  left: 0;
  bottom: -30px;
  z-index: 0;
}
.green-img {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 500px;
}
.accordion-button:not(.collapsed)::after {
  background: url("../../assets/img/png/down-arrow.png") !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}
.accordion-button::after {
  background: url("../../assets/img/png/down-arrow.png") !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}
.faq-heading {
  font-weight: 700;
  font-size: var(--font-xxl);
  color: var(--white);
} /* TOKEN-INFORMATION */
.blur-card {
  background: linear-gradient(
    131.88deg,
    rgba(255, 255, 255, 0.1) 12.67%,
    rgba(255, 255, 255, 0.03) 63.4%
  );
  border: 0.33px solid #ff7944a2;
  padding: 5% 2%;
  border-radius: 7.5px;
}
.text-purple {
  color: var(--pink);
}
.token-img {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.text-shadow {
  text-shadow: -2.5714285373687744px -1.2857142686843872px 37.28571319580078px #f24be0;
  background: linear-gradient(93.77deg, #3dcdff 12.63%, #b119ff 57.88%),
    linear-gradient(0deg, #ffffff, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mw-635 {
  max-width: 635px;
}
.mw-322 {
  max-width: 322px;
}
.absolute-img {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 800px;
  z-index: -1;
}
/*====================================================== ROADMAP-SECTION ======================================================*/
.bg-img-roadmap {
  background: url("../../assets/img/png/roadmap-bg.png");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
  /* min-height: 100vh; */
  /* background-size: cover */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 5%;
  padding-bottom: 10%;
}
.road_map {
  padding-left: 100px;
  padding-right: 100px;
}
.road_map ul {
  max-width: 450px;
}
.road-map ul {
  max-height: 200px;
  overflow: auto;
}
.road-map ul::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.rectangle {
  position: relative;
}

.rectangle h2 {
  position: absolute;
  top: 45%;
  left: 32%;
  transform: translate(-50%, -45%);
  background: linear-gradient(262.63deg, #3dcdff 17.6%, #b119ff 49.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.road_map_3 {
  left: 35% !important;
}
.line {
  background-color: #f4faff;
  width: 100%;
  height: 12px;
  position: absolute;
  top: 162px;
  right: -120px;
  transform: rotate(11deg);
}

.line2 {
  background-color: #f4faff;
  width: 100%;
  height: 12px;
  position: absolute;
  top: 20px;
  right: -120px;
  transform: rotate(-16deg);
}

.line3 {
  background-color: white;
  width: 100%;
  height: 5px;
  transform: rotate(90deg);
}
.mw-600 {
  max-width: 600px;
}
.escrow-details-value {
  display: center;
  align-items: center;
  gap: 5px;
  padding-left: 10px;
  
}
.logo-solana {
  height: 20px;
  width: 20px;
}

/* OUR-TEAM */
.our-team-card-1 {
  max-width: 374px;
  margin: auto;
  min-height: 500px;
  background-image: url("../../assets/img/png//our-team-1.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding: 10px 30px;
  transition: all 0.3s ease-in-out;
  border: 0.94px solid #b119ff;
  border-radius: 8px;
}

.our-team-card-2 {
  max-width: 400px;
  margin: auto;
  min-height: 500px;
  background-image: url("../../assets/img/png//our-team-2.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding: 10px 30px;
  transition: all 0.3s ease-in-out;
  border: 0.94px solid #3dcdff;
  border-radius: 8px;
}
.our-team-card-3 {
  max-width: 400px;
  margin: auto;
  min-height: 500px;
  background-image: url("../../assets/img/png//our-team-3.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10px 30px;
  transition: all 0.3s ease-in-out;
  border: 0.94px solid #f24be0;
  border-radius: 8px;
  background-position: center;
}
.our-team-card-1:hover .new,
.our-team-card-2:hover .new,
.our-team-card-3:hover .new {
  display: none;
}
.our-team-card-1:hover .block,
.our-team-card-2:hover .block,
.our-team-card-3:hover .block {
  display: block !important;
}
.our-team-card-1:hover {
  box-shadow: 0px 3px 30px 0px #b119ff;
  border-radius: 10px;
  transform: translateY(20px);
}

.our-team-card-2:hover {
  box-shadow: 0px 3px 30px 0px #3dceff;
  border-radius: 10px;
  transform: translateY(20px);
}

.our-team-card-3:hover {
  box-shadow: 0px 3px 30px 0px #f24be0;
  border-radius: 10px;
  transform: translateY(20px);
}
.gradient-bg {
  position: absolute;
  bottom: -20px;
  left: -5px;
  width: 100%;
  /* border: 1px solid white; */
  border-radius: 8px;
  height: 270px;
  transform: rotate(1.2deg);
}
.z-1 {
  z-index: 1;
}

.z-10 {
  z-index: 10;
}
.w-100px {
  width: 100px;
}
.h-100px {
  height: 100px;
}
.team-img {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -40%);
}
.new {
  height: 100px;
}
.block {
  height: 100px;
}

.modal-dialog-scrollable .modal-body::-webkit-scrollbar {
  width: 10px;
}

.modal-dialog-scrollable .modal-body::-webkit-scrollbar-thumb {
  background: #b119ff;
  border-radius: 10px;
}

.team-slider .slick-list {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 35px;
  z-index: 9999;
  font-size: var(--font-sm);
  color: var(--color-white);
  background: linear-gradient(40deg, #ff00ab 29.44%, #4d00ff 81.54%);
  border-radius: 50%;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  animation: down-element-animation 2s infinite;
}

/* modal css here */

.modal-content {
  background: black !important;
  padding: 10px;
  border-radius: 30px !important;
}
.cursor-pointer {
  cursor: pointer;
}
.z-5 {
  z-index: 5;
}
/* background img here css start  */

.bg_img_1 {
  top: 37px;
  z-index: 1;
  width: 30%;
  left: 0;
}
.bg_img_2 {
  top: -150px;
  z-index: 1;
  width: 30%;
  left: 0;
}
.bg_img_3 {
  top: 290px;
  z-index: 1;
  width: 30%;
  left: 0;
}
.bg_img_4 {
  bottom: 190px;
  z-index: 1;
  width: 30%;
  left: 0;
}
.bg_img_5 {
  bottom: -250px;
  z-index: 1;
  width: 30%;
  left: 0;
}
.bg_img_green_1 {
  bottom: -250px;
  z-index: 1;
  width: 30%;
  right: 0;
}
.bg_img_green_2 {
  bottom: -250px;
  z-index: 1;
  width: 30%;
  right: 0;
}
.bg_img_green_3 {
  top: 550px;
  z-index: 1;
  width: 30%;
  right: 0;
}
.bg_img_green_4 {
  bottom: -150px;
  z-index: 0;
  width: 30%;
  right: 0;
}

/*  MEDIA QUERIES */

/* HAMBURGER ICON STYLE  */
@media (min-width: 1599.98px) {
  .slider .next-arrow {
    position: absolute;
    top: 50%;
    right: -50px;
  }
  .slider .prev-arrow {
    position: absolute;
    top: 50%;
    left: -50px;
  }

  .team-slider .next-arrow {
    position: absolute;
    top: 50%;
    right: -100px;
  }
  .team-slider .prev-arrow {
    position: absolute;
    top: 50%;
    left: -100px;
  }
}
@media (max-width: 1699.98px) {
  .rectangle h2 {
    left: 37% !important;
  }
  .road_map_3 {
    left: 39% !important;
  }
}
@media (max-width: 1599.98px) {
  .rectangle h2 {
    left: 40% !important;
  }
  .absolute-img {
    max-width: 700px;
    top: 30px;
    right: 0;
  }
  .slider .next-arrow {
    position: absolute;
    top: 50%;
    right: -5px;
    margin-right: 5px;
    z-index: 1;
  }
  .slider .prev-arrow {
    position: absolute;
    top: 50%;
    left: -5px;
    z-index: 1;
    margin-left: 5px;
  }
  .team-slider .next-arrow {
    position: absolute;
    top: 50%;
    right: -5px;
    margin-right: 5px;
    z-index: 1;
  }
  .team-slider .prev-arrow {
    position: absolute;
    top: 50%;
    left: -5px;
    z-index: 1;
    margin-left: 5px;
  }
}
@media (max-width: 1450.98px) {
  .rectangle h2 {
    left: 44% !important;
  }
  .road_map {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 1250.98px) {
  .road_map ul {
    max-width: 350px !important;
  }
}
@media (min-width: 991.98px) {
  .max-width {
    max-width: 915px;
  }
  .modal-dialog {
    max-width: 920px !important;
  }
}
@media (max-width: 1399.98px) {
  .our-team-card-1 {
    max-width: 350px;
  }
  .our-team-card-2 {
    max-width: 350px;
  }
  .our-team-card-3 {
    max-width: 350px;
  }
  .nav-logo {
    left: 52%;
    transform: translateX(-52%);
    max-width: 200px;
  }

  .custom {
    padding: 0 17% 0 5% !important;
  }

  .nav-btn {
    width: 200px;
    height: 50px;
  }
  .absolute-img {
    max-width: 600px;
    top: 30px;
    right: 0;
  }
  .bg-img-roadmap {
    background-position-y: center;
  }
  .line {
    background-color: #f4faff;
    width: 100%;
    height: 5px;
    position: absolute;
    top: 110px;
    right: -110px;
    transform: rotate(15deg);
  }
  .line2 {
    background-color: #f4faff;
    width: 100%;
    height: 5px;
    position: absolute;
    top: 50px;
    right: -120px;
    transform: rotate(-13deg);
  }
  .our-team-card-1,
  .our-team-card-2 {
    background-size: cover;
    background-position-x: right;
  }
  .our-team-card-3 {
    background-position-x: center;
  }
}

@media (max-width: 1199.98px) {
  .our-team-card-1 {
    max-width: 400px;
  }
  .our-team-card-2 {
    max-width: 400px;
  }
  .our-team-card-3 {
    max-width: 400px;
  }
  .nav-logo {
    left: 50%;
    transform: translateX(-50%);
    max-width: 200px;
  }

  .custom {
    padding: 0 17% 0 5% !important;
  }
  .absolute-img {
    max-width: 500px;
    top: 60px;
    right: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 720px !important;
  }
  .max-width {
    max-width: 750px;
  }
  .purple-img {
    max-width: 500px;
  }
  .green-img {
    max-width: 500px;
  }
  .absolute-img {
    position: inherit;
    max-width: 100%;
  }
  /* NAVBAR OVERLAY TOGGLE */

  .navbarwrapper {
    position: relative;
    width: 29px;
    cursor: pointer;
    height: 25px;
    z-index: 15;
  }

  .first {
    background-color: #fff;
    width: 100%;
    height: 3px;
    position: absolute;
    border-radius: 20px;
    right: 0;
  }

  .second {
    background-color: #fff;
    width: 70%;
    height: 3px;
    position: absolute;
    right: 0;
    border-radius: 20px;
    margin-top: 10px;
  }

  .third {
    background-color: #fff;
    width: 100%;
    height: 3px;
    position: absolute;
    right: 0;
    border-radius: 20px;
    margin-top: 20px;
  }

  .animate .second {
    opacity: 0;
  }

  .animate .first {
    top: 11px;
    transform: rotate(45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 1;
  }

  .animate .third {
    top: -8px;
    transform: rotate(-45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 1;
  }

  .overlay-nav {
    transition: 0.7s ease !important;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    box-shadow: inset 0px 4px 20px rgba(177, 25, 255, 0.8);
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  .token-img {
    max-width: 200px;
    bottom: -20px;
  }
  .gradient-bg {
    position: absolute;
    bottom: -22px;
    left: -5px;
    width: 100%;
  }
}

@media (min-width: 767.98px) {
  .max-width-1297 {
    max-width: 1297px;
  }
}
@media (min-width: 575px) and (max-width: 767.98px) {
  .modal-dialog {
    max-width: 520px !important;
  }
}
@media (max-width: 767.98px) {
  .btn-bg {
    width: 200px;
    height: 55px;
  }

  .btn-bg-transparent {
    width: 270px;
    height: 60px;
  }
  .blur-card {
    padding: 30px 20px;
  }
  .token-img {
    max-width: 200px;
    bottom: -20px;
  }
  .mw-600 {
    max-width: 400px;
  }

  .max-width {
    max-width: 550px;
  }
  .road-map svg {
    max-height: 200px;
  }
}

@media (max-width: 575.98px) {
  .our-team-card-1 {
    padding: 5px 15px;
    max-width: 320px;
    background-size: cover;
    margin: auto;
    min-height: 450px;
  }
  .our-team-card-2 {
    padding: 5px 15px;
    max-width: 320px;
    background-size: cover;
    margin: auto;
    min-height: 450px;
  }
  .our-team-card-3 {
    padding: 5px 15px;
    max-width: 320px;
    background-size: cover;
    margin: auto;
    min-height: 450px;
  }
  .mw-600 {
    max-width: 300px;
  }
  .btn-bg {
    width: 170px;
    height: 45px;
  }

  .btn-bg-transparent {
    width: 220px;
    height: 45px;
  }
  .purple-img {
    max-width: 300px;
  }
  .green-img {
    max-width: 300px;
  }
  .token-img {
    max-width: 120px;
  }
  .max-width {
    max-width: 100%;
  }

  .back-to-top {
    bottom: 15px;
    right: 10px;
    width: 50px;
    height: 50px;
  }
}
