.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  max-width: 750px;
  border: 2px solid #000;
  padding: 20px;
  background-color: var(--bkg-color);
  text-align: left;

  &__container {
    display: flex;
    gap: 20px;
    margin: auto;
    text-align: center;

    @media only screen and (max-width: 800px) {
      flex-direction: column;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;

    @media only screen and (max-width: 800px) {
      text-align: center;
    }

    &-title {
      color: var(--text-color);
      margin-bottom: 15px;
    }

    &-price {
      margin: 10px 0;
      color: var(--text-color);
    }

    input::placeholder {
      color: var(--text-color) !important;
    }

    &-alert {
      display: flex;
      gap: 10px;
      text-align: center;
      margin: auto;
    }
  }

  &__button-cotainer {
    display: flex;
    gap: 10px;
    padding: 20px 0;


    @media only screen and (max-width: 800px) {
      flex-direction: column;
    }

    &__button {
      margin-top: 25px;
    }
  }
}
