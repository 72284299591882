.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  max-width: 750px;
  border: 2px solid #000;
  padding: 20px;
  background-color: var(--bkg-color);
  text-align: center;
  align-items: center;
  &__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    @media only screen and (max-width: 800px) {
      text-align: center;
    }

    &-title {
      color: var(--text-color);
      margin-bottom: 15px;
    }

    &-price {
      margin: 10px 0;
      color: var(--text-color);
    }
  }
}

.list-wallets{
  align-items: center;
}
