.MuiOutlinedInput-root {
  color: var(--text-color);
  border: 1px solid var(--text-color);
}

.MuiOutlinedInput-root input {
  color: var(--text-color);
}

.MuiOutlinedInput-root svg {
  fill: var(--text-color);
}

::placeholder {
  color: var(--text-color) !important;
}


*[hidden] {
  display: none !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
