/* @import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;600;700&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;1,700&display=swap');

* {
  outline: none;
  box-shadow: none;
  text-shadow: none;
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

body {
  --text-color: #1a1c1e;
  --bkg-color: #fff;
  --secondary-color: #f5f5f5;
}

body.dark-theme {
  --text-color: #eee;
  --bkg-color: #1a1c1e;
  --secondary-color: #383838;
}

body {
  font-family: "Roboto", sans-serif;
  background: var(--bkg-color);
  color: var(--text-color);
  font-size: 16px;
  transition-duration: 300ms;
  overflow: auto !important;
  padding-right: 0 !important;
  margin: 0 !important;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  color: var(--text-color);
}

h1 {
  margin: 0.67em 0;
  font-size: 3.4em;
  line-height: 1.2em;

  @media only screen and (max-width: 650px) {
    font-size: 3em;
  }
}

p {
  font-size: 1.2em;
}

.not-allowed {
  cursor: not-allowed !important;
}

#notification-wrapper .toast-notification {
  right: unset !important;
  top: unset !important;
  bottom: 50px !important;
  left: 30px !important;
  text-align: start !important;
  z-index: 9999999 !important;
}

#notification-wrapper .toast-notification span {
  padding: 15px 30px !important;
}

img {
  max-width: 100%;
}

.container {
  max-width: 1200px;
  margin: auto;
  padding-left: 24px;
  padding-right: 24px;
}

/* @media only screen and (max-width: 600px) {
  .container {
    padding-left: 24px;
    padding-right: 24px;
  }
} */
